import { useEffect, useState } from 'react';
import { InfosLoja } from '../shared/types';
import { DescLoja as DescLojaDictionary, InfosLoja as InfosLojaDictionary } from "../shared/utils/dictionary";
import { isMobile, scrollToTop } from '../shared/utils/javascript';
import { getInfosLoja } from '../service/api';
import nossaHistoria from '../assets/nossaHistoria.webp';

export const SobreNos = () => {

    const [loja, setLoja] = useState<InfosLoja>();

    function createMarkup(html: any) {
        return { __html: html };
    }

    const handleGetInfosLoja = async () => {
        const result = await getInfosLoja()
        setLoja(result?.data)
    }

    useEffect(() => {
        handleGetInfosLoja();
    },[])

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div className="px-28 py-16 bg-secondary flex flex-col gap-14 mobile:px-3 mobile:gap-3">
            {/* Titulo */}
            <div className="flex flex-row items-center gap-3">
                <h1 className="min-w-max text-accent font-semibold">{isMobile() ? "SOBRE A NOSSA EMPRESA" : "CONHEÇA UM POUCO SOBRE A NOSSA EMPRESA"}</h1>
                <hr className="h-0.5 bg-accent w-full" />
            </div>

            {/* Sobre nós */}
            <div className="flex flex-col items-center py-10 text-black mobile:py-5">
                <div className="grid grid-cols-3 gap-20 w-full items-center mobile:flex mobile:flex-col-reverse mobile:gap-5 mobile:shadow mobile:p-3">
                        <div className="w-full">
                        <img loading="lazy" src={nossaHistoria} alt="A nossa história" className="object-cover max-h-[500px]" />
                        </div>
                    <div className='h-full items-start justify-center flex col-span-2 shadow p-3' style={{background: "linear-gradient(rgb(241, 241, 241) 0%, rgb(255, 255, 255) 99.1%)"}}>
                        <p className="text-justify" dangerouslySetInnerHTML={createMarkup(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.DESCRICAO])} />
                    </div>
                </div>
            </div>
        </div>
    )
}