import { useContext, useEffect, useState } from "react";
import { Email, Endereco, InfosLoja, NomeLoja } from "../shared/types";
import { Endereco as EnderecoDictionary, InfosLoja as InfosLojaDictionary, Telefone as TelefoneDictionary, Email as EmailDictionary, NomeLoja as NomeLojaDictionary, DescLoja as DescLojaDictionary } from "../shared/utils/dictionary";
import { useNavigate } from "react-router-dom";

import logoLitoral from '../assets/logoLitoral.webp'
import { getEmails, getEnderecos, getInfosLoja, getLoja, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { isMobile } from "../shared/utils/javascript";

export const Footer = () => {

    const { telefone, ip } = useContext(AuthContext);

    const navigate = useNavigate()
    const [loja, setLoja] = useState<InfosLoja>();
    const [endereco, setEndereco] = useState<Endereco[]>([])
    const [email, setEmail] = useState<Email[]>([])
    const [nomeLoja, setNomeLoja] = useState<NomeLoja>()

    const handleGetInfosLoja = async () => {
        const result = await getInfosLoja()
        setLoja(result?.data)
    }

    useEffect(() => {
        handleGetInfosLoja();
    },[])

    const segundoPontoIndex = loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.DESCRICAO].indexOf('.', loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.DESCRICAO].indexOf('.'));
    const textoAteSegundoPonto = loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.DESCRICAO].substring(0, segundoPontoIndex ?? 1 + 1);

    const telefoneWhats = telefone.filter((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")

    function removeHTMLTags(input: string) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.body.textContent || "";
    }

    const textoSemHtml = removeHTMLTags(textoAteSegundoPonto ?? "")

    const handleGetNomeLoja = async () => {
        try {
            const result = await getLoja()
            setNomeLoja(result?.data.retorno ?? {
                nome_empresa: ''
            }) 
        } catch {
            //
        }
    }
    const handleGetEndereco = async () => {
        try {
            const result = await getEnderecos()
            setEndereco(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }
    const handleGetEmail = async () => {
        try {
            const result = await getEmails()
            setEmail(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    useEffect(() => {
        handleGetEmail();
        handleGetEndereco();
        handleGetNomeLoja();
    },[])

    return (
        <div className="bg-black text-white">
            <div className="py-8 px-24 flex flex-row justify-center gap-10 mobile:flex-col-reverse mobile:px-3">
                {isMobile() ? null : (
                    <>
                        <div className="flex flex-col gap-5 h-full flex-1 items-center">
                            <h2 className="self-start">Um pouco sobre nós</h2>
                            <h3 className="self-start">{textoSemHtml}.</h3>
                        </div>
                        <div className="flex flex-col gap-5 h-full flex-1 items-center">
                            <div className="flex flex-col gap-5">
                                <h2>Mapa do site</h2>
                                <div className="flex flex-col gap-1">
                                    <h3 className="cursor-pointer" onClick={() => navigate('/')}>Início</h3>
                                    <h3 className="cursor-pointer" onClick={() => navigate('/estoque')}>Quero comprar</h3>
                                    <h3 className="cursor-pointer" onClick={() => navigate('/venda-seu-veiculo')}>Quero vender</h3>
                                    <h3 className="cursor-pointer" onClick={() => navigate('/financiamento')}>Financiamento</h3>
                                    <h3 className="cursor-pointer" onClick={() => navigate('/sobre-nos')}>Quem somos</h3>
                                    <h3 className="cursor-pointer" onClick={() => navigate('/contato')}>Contato</h3>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="flex flex-col gap-5 h-full flex-1 items-center">
                    <h2 className="self-start">Localização</h2>
                    <div className="flex flex-col gap-1 self-start">
                        <div className="flex items-center gap-3">
                            <i className="ri-map-2-line ri-lg"></i>
                            <div className="mobile:flex mobile:flex-row mobile:gap-2">
                                <h3>{endereco?.[0]?.[EnderecoDictionary.LOGRADOURO]}</h3>
                                <h3>{endereco?.[0]?.[EnderecoDictionary.BAIRRO]} - {endereco?.[0]?.[EnderecoDictionary.CIDADE]}, {endereco?.[0]?.[EnderecoDictionary.UF]}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile() ? (
                    <div className="flex">
                        <div className="flex flex-col gap-5 h-full flex-1 items-center">
                        <h2 className="self-start">Números</h2>
                        <div className="flex flex-col gap-2 self-start">
                            {telefoneWhats.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => {
                                        postWhats({
                                            ip: ip,
                                            dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                            codVeiculo: 0
                                        })
                                        window.open(`https://api.whatsapp.com/send?phone=+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                    }}>
                                    <i className="ri-whatsapp-line ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                            {telefoneRamal.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")}>
                                    <i className="ri-phone-line ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 h-full flex-1 items-center">
                        <h2 className="self-start">E-mails</h2>
                        <div className="flex flex-col gap-2 self-start">
                            {email.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.email} onClick={() => window.open(`mailto:${x[EmailDictionary.EMAIL]}`, "_blank")}>
                                    <i className="ri-mail-send-line ri-lg"></i>
                                    <h3>{x[EmailDictionary.EMAIL]}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                    </div>
                ) : (
                    <>
                    <div className="flex flex-col gap-5 h-full flex-1 items-center">
                        <h2 className="self-start">Números</h2>
                        <div className="flex flex-col gap-2 self-start">
                            {telefoneWhats.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => {
                                        postWhats({
                                            ip: ip,
                                            dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                            codVeiculo: 0
                                        })
                                        window.open(`https://api.whatsapp.com/send?phone=+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                    }}>
                                    <i className="ri-whatsapp-line ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                            {telefoneRamal.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")}>
                                    <i className="ri-phone-line ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 h-full flex-1 items-center">
                        <h2 className="self-start">E-mails</h2>
                        <div className="flex flex-col gap-2 self-start">
                            {email.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.email} onClick={() => window.open(`mailto:${x[EmailDictionary.EMAIL]}`, "_blank")}>
                                    <i className="ri-mail-send-line ri-lg"></i>
                                    <h3>{x[EmailDictionary.EMAIL]}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                    </>
                )}                
            </div>
            <div className="bg-neutral py-2 flex items-center justify-center">
                {isMobile() ? (
                    <div className="flex flex-col items-center px-3">
                        <p className="flex items-center gap-2 leading-5 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por</p>
                        <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover mt-[-22px] ml-20" src={logoLitoral} alt="Litoral Car" />
                        <span onClick={() => navigate('/termos')} className="underline mt-3 cursor-pointer">Politica de privacidade e termos de uso</span>
                    </div>
                ) : (
                    <p className="flex items-center gap-2 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover" src={logoLitoral} alt="Litoral Car" /> <span onClick={() => navigate('/termos')} className="underline cursor-pointer">Politica de privacidade e termos de uso</span></p>
                )}
            </div>
        </div>
    )
}