import { useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.webp'
import { isMobile } from '../shared/utils/javascript';

export const Header = () => {

    const navigate = useNavigate();

    const verifyRoute = (path: string) => {
        if(path === window.location.pathname) return true
    }

    return (
        <div className="sticky top-0 bg-white px-24 py-5 flex items-center gap-12 z-50 mobile:px-3 mobile:py-3 justify-between border-b-2 border-[#362a67]">
        <img className='cursor-pointer object-cover max-w-32' onClick={() => navigate('/')} src={Logo} alt="Logo" />            
        {isMobile() ? (
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" aria-label="Hamburguer" className="btn text-black outline-none border-none"><i className="ri-menu-line ri-lg"></i></div>
                    <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-max">
                    <li aria-label="Início"><h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2></li>
                    <li aria-label="Estoque"><h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Quero comprar</h2></li>
                    <li aria-label="Venda seu veículo"><h2 onClick={() => navigate('/venda-seu-veiculo')} className={`cursor-pointer ${verifyRoute('/venda-seu-veiculo') ? "font-semibold" : ""}`}>Quero vender</h2></li>
                    <li aria-label="Financiamento"><h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2></li>
                    <li aria-label="Sobre nós"><h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Quem somos</h2></li>
                    <li aria-label="Contato"><h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2></li>
                    </ul>
                </div>
            ) : (
            <div className='text-black flex items-center gap-12'>
                <h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2>
                <h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Quero comprar</h2>
                <h2 onClick={() => navigate('/venda-seu-veiculo')} className={`cursor-pointer ${verifyRoute('/venda-seu-veiculo') ? "font-semibold" : ""}`}>Quero vender</h2>
                <h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2>
                <h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Quem somos</h2>
                <h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2>
            </div>
            )}
        </div>
    )
}