import { Carousel } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { Email, InfosLoja, Slider, Veiculo, Endereco as EnderecoType } from "../shared/types";
import { FiltroEstoque } from "../components/filtroEstoque";
import { CardVeiculo } from "../components/cardVeiculo";
import { useNavigate } from "react-router-dom";
import nossaHistoria from '../assets/nossaHistoria.webp';
import { DescLoja as DescLojaDictionary, InfosLoja as InfosLojaDictionary, Telefone as TelefoneDictionary, Email as EmailDictionary, Endereco as EnderecoDictionary } from "../shared/utils/dictionary";
import { buscarSlide, getEmails, getEnderecos, getEstoque, getInfosLoja, postWhats } from "../service/api";
import { Endereco } from "../shared/utils/constants";
import { AuthContext } from "../shared/context/auth";
const Home: React.FC = () => {
    const navigate = useNavigate()
    const { telefone, ip } = useContext(AuthContext);
    const [slide, setSlide] = useState<Slider[]>([]);
    const [loja, setLoja] = useState<InfosLoja>();
    const [estoque, setEstoque] = useState<Veiculo[]>([]);
    const telefoneWhats = telefone.filter((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")
    const [endereco, setEndereco] = useState<EnderecoType[]>([])
    const [emailState, setEmail] = useState<Email[]>([])
    const estilos = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "bg-accent hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800 hidden",
            on: "bg-primary dark:bg-primary hidden"
          },
          base: "h-2 w-2 rounded-full",
          wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white group-hover:bg-white/80 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-accent/30 dark:group-hover:bg-accent/60 dark:group-focus:ring-accent/70 sm:h-10 sm:w-10",
          icon: "h-5 w-5 text-primary dark:text-primary sm:h-6 sm:w-6"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const estilosHiddenControls = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "hidden",
            on: "hidden"
          },
          base: "hidden",
          wrapper: "hidden"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "hidden",
          icon: "hidden"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const handleGetSlide = async () => {
        const result = await buscarSlide();
        setSlide(result?.data)
    }
    const handleGetEstoque = async () => {
    try {
        const result = await getEstoque()
        setEstoque(result?.data.retorno ?? []) 
    } catch {
        //
    }
    }
    const handleGetInfosLoja = async () => {
        const result = await getInfosLoja()
        setLoja(result?.data)
    }
    const handleGetEmail = async () => {
      try {
          const result = await getEmails()
          setEmail(result?.data.retorno ?? []) 
      } catch {
          //
      }
  }
  const handleGetEndereco = async () => {
    try {
        const result = await getEnderecos()
        setEndereco(result?.data.retorno ?? []) 
    } catch {
        //
    }
}
    useEffect(() => {
        handleGetSlide();
        handleGetInfosLoja();
        handleGetEstoque();
        handleGetEmail();
        handleGetEndereco();
    },[])
    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
    const findBanner = () => {
      const mobile = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
      const desktop = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 
      return isMobile() && mobile?.length > 0 ? mobile : desktop
    }
    function createMarkup(html: any) {
      return { __html: html };
    }
    return (
        <div className="bg-secondary">
            {/* Carrossel */}
            <div className="h-[86vh] mobile:h-[28vh] mobile:relative">
                <Carousel slideInterval={3000} theme={findBanner().length <= 1 ? estilosHiddenControls : estilos}>
                {findBanner()?.map((x: Slider) => (
                    <img loading="lazy" key={x.id} className='object-cover h-full' src={`${process.env.REACT_APP_FOTO}${x.arquivo}`} alt="..." />
                ))}
                </Carousel>
            </div>
            {/* Filtro */}
            <div className="flex items-center w-full justify-center gap-7 mt-16 mobile:px-3 flex-wrap">
              <h1 className="min-w-max text-accent font-semibold">UM POUCO DO NOSSO ESTOQUE</h1>
              {isMobile() ? null : (
                  <FiltroEstoque />
                  )}
            </div>
              {isMobile() ? (
                <div className="flex justify-center items-center w-full mt-5">
                    <FiltroEstoque />
                </div>
              ) : null}
            {/* Estoque */}
            <div className="flex flex-col gap-16 justify-center mt-16 items-center pb-6 mobile:pb-2 mobile:gap-8">
                <div className="grid grid-cols-4 px-24 gap-11 mobile:grid-cols-1 mobile:px-3 mobile:gap-5">
                    {estoque?.slice(0,4).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <button aria-label="Veja nosso estoque completo" onClick={() => navigate('/estoque')} className="btn btn-primary w-fit text-white rounded-full px-24 mobile:text-sm mobile:px-8">VEJA O NOSSO ESTOQUE COMPLETO</button>
            </div>
            {/* Nossa história */}
            <div className="flex flex-col items-center pt-10 text-accent">
                <div className="flex flex-row gap-20 mobile:flex-col mobile:px-3 mobile:gap-1 w-full px-24">
                  {isMobile() ? null : (
                    <img loading="lazy" src={nossaHistoria} alt="A nossa história" className="object-cover max-h-[500px]" />
                  )}
                    <div className="flex flex-col gap-8 items-center w-full shadow p-5" style={{background: "linear-gradient(rgb(241, 241, 241) 0%, rgb(255, 255, 255) 99.1%)"}}>
                        <p className="w-full h-full text-justify" dangerouslySetInnerHTML={createMarkup(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.DESCRICAO])} />
                    </div>
                </div>
            </div>
          {/* Contato */}
          <div className="px-24 flex flex-row my-16 gap-16 mobile:flex-col mobile:px-3 mobile:my-10 mobile:gap-3">
            <div className="w-full mobile:h-[500px]">
              <iframe title='Mapa' src={Endereco.link} width="100%" height="100%" style={{border:"10px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="shadow p-10 gap-7 flex flex-col mobile:px-3 mobile:py-8 text-accent min-w-[500px] mobile:min-w-0" style={{background: "linear-gradient(rgb(241, 241, 241) 0%, rgb(255, 255, 255) 99.1%)"}}>
                <h1 className="">Venha nos visitar:</h1>
                <div className="flex flex-col gap-3">
                    <h2>Números</h2>
                    <div className="flex flex-col gap-1">
                        {telefoneWhats.map((x) => (
                                <div key={x.telefone} className="flex items-center gap-3 cursor-pointer" onClick={() => {
                                    postWhats({
                                        ip: ip,
                                        dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                        lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                        codVeiculo: 0
                                    })
                                    window.open(`https://api.whatsapp.com/send?phone=+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                }}>
                                    <i className="ri-whatsapp-line ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                        {telefoneRamal.map((x) => (
                            <div key={x.telefone} className="flex items-center gap-3 cursor-pointer" onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")}>
                                <i className="ri-phone-line ri-lg"></i>
                                <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <h2>E-mails</h2>
                    <div className="flex flex-col gap-1">
                        {emailState.map((x) => (
                            <div key={x.email} className="flex items-center gap-3 cursor-pointer" onClick={() => window.open(`mailto:${x[EmailDictionary.EMAIL]}`, "_blank")}>
                                <i className="ri-mail-send-line ri-lg"></i>
                                <h3>{x[EmailDictionary.EMAIL]}</h3>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <h2>Localização</h2>
                    <div className="flex flex-col gap-1">
                        <div className="flex flex-col gap-1 self-start">
                            <div className="flex items-center gap-3">
                                <i className="ri-map-2-line ri-lg"></i>
                                <div>
                                    <h3>{endereco?.[0]?.[EnderecoDictionary.LOGRADOURO]}</h3>
                                    <h3>{endereco?.[0]?.[EnderecoDictionary.BAIRRO]} - {endereco?.[0]?.[EnderecoDictionary.CIDADE]}, {endereco?.[0]?.[EnderecoDictionary.UF]}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <h2>Redes sociais</h2>
                    <div className="flex flex-row gap-7">
                        <i onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.INSTAGRAM], "_blank")} className="ri-instagram-line ri-lg cursor-pointer"></i>
                    </div>
                </div>
              </div>
          </div>
        </div>
    )
}
export default Home;