import { Routes as RoutesDOM, Route } from "react-router-dom";

import Home from "../pages/home";
import { Estoque } from "../pages/estoque";
import { SobreNos } from "../pages/sobreNos";
import { Contato } from "../pages/contato";
import { Avaliacao } from "../pages/avaliacao";
import { Veiculo } from "../pages/veiculo";
import { Login } from "../pages/Admin/login";
import { HomeAdmin } from "../pages/Admin/home";
import { VisitasAdmin } from "../pages/Admin/visitas";
import { WhatsappAdmin } from "../pages/Admin/whatsapp";
import { Configuracoes } from "../pages/Admin/configuracao";
import { Banner } from "../pages/Admin/banner";
import { Termos } from "../pages/termos";
import { Financiamento } from "../pages/financiamento";

const Routes = () => {
  return (
    <RoutesDOM>
      {/* <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<PageNotFound />} /> */}
      <Route path="/" element={<Home />}/>
      <Route path="/sobre-nos" element={<SobreNos />}/>
      <Route path="/estoque" element={<Estoque />}/>
      <Route path="/contato" element={<Contato />}/>
      <Route path="/venda-seu-veiculo" element={<Avaliacao />}/>
      <Route path="/veiculo" element={<Veiculo />}/>
      <Route path="/financiamento" element={<Financiamento />}/>
      <Route path="/termos" element={<Termos />}/>

      <Route path="/admin" element={<Login />}/>
      <Route path="/admin/home" element={<HomeAdmin />}/>
      <Route path="/admin/visitas" element={<VisitasAdmin />}/>
      <Route path="/admin/whatsapp" element={<WhatsappAdmin />}/>
      <Route path="/admin/configuracoes" element={<Configuracoes />}/>
      <Route path="/admin/banner" element={<Banner />}/>
    </RoutesDOM>
  );
};

export default Routes;
