import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEstoque } from '../service/api';
import { Veiculo } from '../shared/types';
import { isMobile } from '../shared/utils/javascript';

type BuscaQueryType = {
    categoria: string,
    marca: string,
    modelo: string,
    precoDe: string,
    anoDe: string,
    busca: string,
    [key: string]: string;
}

export const FiltroEstoque = () => {

    const navigate = useNavigate()
    const [filtroSelect, setFiltroSelect] = useState({
        categoria: "",
        marca: "",
        modelo: "",
        precoDe: "",
        anoDe: "",
    });
    const [estoque, setEstoque] = useState<Veiculo[]>([]);
    const [buscaQuery, setBuscaQuery] = useState<BuscaQueryType>();
    const [optionsCategoria, setOptionsCategoria] = useState([{value: "Categoria", label: "Categoria" }]);
    const [optionsModelo, setOptionsModelo] = useState([{value: "Modelo", label: "Modelo" }]);
    const [optionsMarca, setOptionsMarca] = useState([{value: "Marca", label: "Marca" }]);
    const [optionsValorDe, setOptionsValorDe] = useState([{value: "Valor de", label: "Valor de" }]);
    const [optionsValorAte, setOptionsValorAte] = useState([{value: "Ano de", label: "Ano de" }]);

    const handleNavigateFilterSelect = (selectedOption: any) => {
        const paramsObj = { 
            categoria: selectedOption.categoria === "Categoria" ? "" : selectedOption.categoria,
            marca: selectedOption.marca === "Marca" ? "" : selectedOption.marca,
            modelo: selectedOption.modelo === "Modelo" ? "" : selectedOption.modelo,
            precoDe: selectedOption.precoDe === "Valor de" ? "" : selectedOption.precoDe,
            anoDe: selectedOption.anoDe === "Ano de" ? "" : selectedOption.anoDe,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    const handleSelectChangeFiltro = async (tipo: string, value: string) => {
        setFiltroSelect(prevState => ({
            ...prevState,
            [tipo]: value
        }));
        if(tipo === "categoria") {
            setFiltroSelect(prevState => ({
                ...prevState,
                marca: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                anoDe: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
        }
        if(tipo === "marca") {
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                anoDe: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
        }
        if(tipo === "modelo") {
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                anoDe: ""
            }))
        }
        if(tipo === "precoDe") {
            setFiltroSelect(prevState => ({
                ...prevState,
                anoDe: ""
            }))
        }
    }

    const handleGetEstoque = async () => {
        try {
            const result = await getEstoque()
            setEstoque(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    useEffect(() => {
        handleGetEstoque();
        const queryString = window.location.search;       
        const paramsString = new URLSearchParams(queryString).get('params');
    
        if (paramsString) {
            try {
                const paramsObj = JSON.parse(decodeURIComponent(paramsString));
                if(paramsObj.hasOwnProperty('categoria')) {
                    setBuscaQuery(paramsObj)
                }
            } catch (error) {
                //
            }
        }
    },[])

    const handleFilterInput = async () => {
        const categorias = estoque.map((x) => {
            return x.categoria
          })
        const listaCategorias = categorias.filter((element, index) => {
            return categorias.indexOf(element) === index;
        });
        listaCategorias.unshift("Categoria")
        setOptionsCategoria(listaCategorias.map((x) => {
            return {value: x, label: x }
        }))
    
        const filtroMarca = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            
            return categoriaMatch;
          });
        const marca = filtroMarca.map((x) => {
            return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaMarca.unshift("Marca")
        setOptionsMarca(listaMarca.map((x) => {
            return {value: x, label: x }
        }))
    
        const filtroModelo = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            
            return categoriaMatch && marcaMatch;
          });
        const modelo = filtroModelo.map((x) => {
            return x.modelo
        })
        const listaModelo = modelo.filter((element, index) => {
            return modelo.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaModelo.unshift("Modelo")
        setOptionsModelo(listaModelo.map((x) => {
            return {value: x, label: x }
        }))

        const filtroValorDe = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            const modeloMatch = filtroSelect.modelo === '' || filtroSelect.modelo === 'Modelo' || item.modelo === filtroSelect.modelo;

            return categoriaMatch && marcaMatch && modeloMatch;
          });
        const valorDe = filtroValorDe.map((x) => {
            return x.valor.toString()
        })
        const listaValorDe = valorDe.filter((element, index) => {
            return valorDe.indexOf(element) === index;
        });
        
        listaValorDe.unshift("Valor de")
        setOptionsValorDe(listaValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => {
            return {value: x, label: x }
        }))

        const filtroAnoDe = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            const modeloMatch = filtroSelect.modelo === '' || filtroSelect.modelo === 'Modelo' || item.modelo === filtroSelect.modelo;
            const valorDeMatch = filtroSelect.precoDe === '' || filtroSelect.precoDe === 'Valor de' || Number(filtroSelect.precoDe) <= Number(item.valor);
            
            return categoriaMatch && marcaMatch && modeloMatch && valorDeMatch;
          });
        const anoDe = filtroAnoDe.map((x) => {
            return x.ano.toString()
        })
        const listaAnode = anoDe.filter((element, index) => {
            return anoDe.indexOf(element) === index;
        });
        
        listaAnode.unshift("Ano de")
        setOptionsValorAte(listaAnode.sort((a,b) => Number(a) - Number(b)).map((x) => {
            return {value: x, label: x }
        }))
    }
    
    useEffect(() => {
        handleFilterInput();
    },[estoque])

    useEffect(() => {
        handleFilterInput();
    },[filtroSelect])

    useEffect(() => {
        for (const propriedade in buscaQuery) {
            handleSelectChangeFiltro(propriedade, buscaQuery[propriedade])
        }
    },[buscaQuery])
    
    return (
        <div className={`flex mobile:w-full flex-wrap text-black ${window.location.pathname === "/" ? "justify-center" : ""}`}>
            <div className={`flex flex-wrap flex-row gap-5 w-full mobile:flex-wrap ${window.location.pathname === "/" ? "justify-center" : ""}`}>
                {isMobile() ? (
                    <div className='grid grid-cols-1 gap-3 justify-center w-full px-2'>
                        <select value={filtroSelect.categoria ? filtroSelect.categoria : buscaQuery?.categoria} onChange={async (event) => await handleSelectChangeFiltro('categoria', event.target.value)} className="select select-bordered select-radius min-w-[47%] mobile:w-full h-10 min-h-10 border-[#CED4DA]">
                        {optionsCategoria.map((x) => (
                            <option key={x.value}>{x.label}</option>
                        ))}
                        </select>
                        <select value={filtroSelect.marca ? filtroSelect.marca : buscaQuery?.marca} onChange={async (event) => await handleSelectChangeFiltro('marca', event.target.value)} className="select select-bordered select-radius min-w-[47%] mobile:w-full h-10 min-h-10 border-[#CED4DA]">
                            {optionsMarca.map((x) => (
                                <option key={x.value}>{x.label}</option>
                            ))}
                        </select>
                        <select value={filtroSelect.modelo ? filtroSelect.modelo : buscaQuery?.modelo} onChange={async (event) => await handleSelectChangeFiltro('modelo', event.target.value)} className="select select-bordered select-radius min-w-min max-w-[29vw] mobile:max-w-screen-2xl mobile:w-full h-10 min-h-10 border-[#CED4DA]">
                            {optionsModelo.map((x) => (
                                <option key={x.value}>{x.label}</option>
                            ))}
                        </select>
                            <div className='grid grid-cols-2 w-full justify-center gap-2'>
                                <select value={filtroSelect.precoDe ? filtroSelect.precoDe : buscaQuery?.precoDe} onChange={async (event) => await handleSelectChangeFiltro('precoDe', event.target.value)} className="select select-bordered select-radius mobile:w-full h-10 min-h-10 border-[#CED4DA]">
                                    {optionsValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => (
                                        <option value={x.value} key={x.value}>{Number(x.label) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x.label)) : x.label}</option>
                                    ))}
                                </select>
                                <select value={filtroSelect.anoDe ? filtroSelect.anoDe : buscaQuery?.anoDe} onChange={async (event) => await handleSelectChangeFiltro('anoDe', event.target.value)} className="select select-bordered select-radius mobile:w-full h-10 min-h-10 border-[#CED4DA]">
                                    {optionsValorAte.map((x) => (
                                        <option value={x.value} key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                        <div className='flex flex-row gap-5 justify-around'>
                            <button className='btn btn-primary rounded-full px-8 text-white min-w-[80vw] w-full h-10 min-h-10' onClick={() => handleNavigateFilterSelect(filtroSelect)}>Buscar resultado</button>
                        </div>
                    </div>
                ) : (
                    <>
                    <select value={filtroSelect.categoria ? filtroSelect.categoria : buscaQuery?.categoria} onChange={async (event) => await handleSelectChangeFiltro('categoria', event.target.value)} className="select select-bordered min-w-52 select-radius h-10 min-h-10 border-[#CED4DA]">
                        {optionsCategoria.map((x) => (
                            <option key={x.value}>{x.label}</option>
                        ))}
                    </select>
                    <select value={filtroSelect.marca ? filtroSelect.marca : buscaQuery?.marca} onChange={async (event) => await handleSelectChangeFiltro('marca', event.target.value)} className="select select-bordered min-w-52 select-radius h-10 min-h-10 border-[#CED4DA]">
                        {optionsMarca.map((x) => (
                            <option key={x.value}>{x.label}</option>
                        ))}
                    </select>
                    <select value={filtroSelect.modelo ? filtroSelect.modelo : buscaQuery?.modelo} onChange={async (event) => await handleSelectChangeFiltro('modelo', event.target.value)} className="select select-bordered min-w-52 select-radius h-10 min-h-10 border-[#CED4DA]">
                        {optionsModelo.map((x) => (
                            <option key={x.value}>{x.label}</option>
                        ))}
                    </select>
                    <select value={filtroSelect.precoDe ? filtroSelect.precoDe : buscaQuery?.precoDe} onChange={async (event) => await handleSelectChangeFiltro('precoDe', event.target.value)} className="select select-bordered min-w-40 select-radius h-10 min-h-10 border-[#CED4DA]">
                        {optionsValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => (
                            <option value={x.value} key={x.value}>{Number(x.label) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x.label)) : x.label}</option>
                        ))}
                    </select>
                    <select value={filtroSelect.anoDe ? filtroSelect.anoDe : buscaQuery?.anoDe} onChange={async (event) => await handleSelectChangeFiltro('anoDe', event.target.value)} className="select select-bordered min-w-40 select-radius h-10 min-h-10 border-[#CED4DA]">
                        {optionsValorAte.map((x) => (
                            <option value={x.value} key={x.value}>{x.label}</option>
                        ))}
                    </select>
                    <div className='flex flex-row gap-5'>
                        <button className='btn btn-primary rounded-full px-8 text-white h-10 min-h-10' onClick={() => handleNavigateFilterSelect(filtroSelect)}>Buscar resultado</button>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}