import { useNavigate } from "react-router-dom"
import { Veiculo } from "../shared/types"
import { Telefone as TelefoneDictionary, Veiculo as VeiculoDictionary } from "../shared/utils/dictionary"
import { ModalProposta } from "./modalProposta"
import semImagem from '../assets/semImagem.webp'
import { useContext } from "react"
import { AuthContext } from "../shared/context/auth"
import { postWhats } from "../service/api"
import { isMobile } from "../shared/utils/javascript"

type CardVeiculoType = {
    veiculo: Veiculo
}

export const CardVeiculo = ({ veiculo }: CardVeiculoType) => {

    const { telefone, ip } = useContext(AuthContext);
    const navigate = useNavigate();

    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")

    const img = `https://litoralcar.com.br/foto-resize-site/X/${veiculo?.[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo?.[VeiculoDictionary.FOTOS][0]}`

    const cambio = veiculo[VeiculoDictionary.OPCIONAIS].find(x => x.codigo === 21)

    const handleNavigate = () => {
        const paramsObj = { 
            cod_veiculo: veiculo[VeiculoDictionary.COD_VEICULO],
            marca: veiculo[VeiculoDictionary.MARCA],
            modelo: veiculo[VeiculoDictionary.MODELO],
            versao: veiculo[VeiculoDictionary.VERSAO]
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/veiculo?params=${paramsString}`;
        navigate(urlComObjeto)
    }

    return (
        <div className="flex flex-col bg-secondary text-accent mobile:min-w-[95vw]" style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"}}>
            <ModalProposta veiculo={veiculo} />
            {veiculo[VeiculoDictionary.FOTOS].length === 0 ? (
                <img loading="lazy" src={semImagem} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover" onClick={handleNavigate} />
            ) : (
                <img loading="lazy" src={img} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover" onClick={handleNavigate} />
            )}
            <div className="p-4 flex flex-col gap-3">
                <h2><span className="font-semibold">{veiculo[VeiculoDictionary.MARCA]} {veiculo[VeiculoDictionary.MODELO]}</span> - {veiculo[VeiculoDictionary.VERSAO]}</h2>
                <div className="grid grid-cols-2">
                    <div>
                        <h3>Quilometragem</h3>
                        <h3>Ano</h3>
                        <h3>Câmbio</h3>
                    </div>
                    <div className="flex flex-col items-end">
                        <h2>{Number(veiculo[VeiculoDictionary.QUILOMETRAGEM]).toLocaleString()}Km</h2>
                        <h2>{veiculo[VeiculoDictionary.ANO] ?? "-"}</h2>
                        <h2>{cambio?.[VeiculoDictionary.DESCRICAO_OPCIONAIS] ?? "-"}</h2>
                    </div>
                </div>
                <hr className="h-0.5 bg-accent w-full" />
                <div className="flex justify-between">
                    <h1>
                        {veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    </h1>
                    <div className="flex gap-2 items-center">
                        <i onClick={() => {
                                postWhats({
                                    ip: ip,
                                    dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                    lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                    codVeiculo: 0
                                })
                                window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                            }} className="ri-whatsapp-line ri-xl cursor-pointer text-green-600"></i>
                        <i onClick={() => {
                                const modal = document.getElementById('modal_proposta') as HTMLDialogElement;;

                                if (modal) {
                                modal.showModal();
                                } else {
                                console.error("Element with ID 'modal_proposta' not found.");
                                }
                            }} className="ri-mail-send-line ri-xl cursor-pointer"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}